// import {useRoute} from "vue-router";


export const useProductStore = defineStore("ProductStore", {
  state: () => ({
    products: [] as any,
    product: {},
    productSvgSource: "" as String,
    productSvg: "" as String,
    productCategories: [] as any,
    productMatTypes: [] as any,
    matClass: "" as String,
    productClassic: {},
  }),
  getters: {
    getProductCategory: (state) => {
      return state.product?.productCategories?.nodes[0]?.databaseId || null;
    },
    getMatTypeSku: (state) => {
      return state.product?.sku?.split("-")[1] || null;
    },
  },
  actions: {
    setProducts(products: Array<object>) {
      this.products = products;
    },
    setClassicProduct(product: object) {
      this.productClassic = product;
    },
    setMatClass(matClass: string) {
      this.matClass = matClass;
    },
    setProductMatTypes(matTypes: Array<object>) {
      this.productMatTypes = matTypes;
    },
    setProduct(product: object) {
      this.product = product;
    },
    setProductSvgSource(url: string) {
      this.productSvgSource = url;
    },
    setProductCategories(categories: Array<object>) {
      this.productCategories = _SortBy(categories, ["menuOrder"]);
    },
    setFilterProducts(products: Array<object>) {
      this.filterProducts = products;
    },
  },
  persist: false,
});
